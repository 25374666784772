import React from 'react';

import { NaturalRoleplayScoreCard } from '@trainhq/trainhq-client-core';

import { useGetAttemptDetailsScorecardData } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const AttemptDetailsScorecard: React.FC = () => {
  const scorecardData = useGetAttemptDetailsScorecardData();

  return (
    scorecardData && (
      <>
        <NaturalRoleplayScoreCard
          personalPerspective={true}
          results={scorecardData}
          scorecardType={scorecardData.executionType === 'CALL_INTELLIGENCE_EVALUATION' ? 'TRANSCRIPT' : 'INSIGHTS'}
          title={
            scorecardData.executionType === 'CALL_INTELLIGENCE_EVALUATION'
              ? scorecardData?.callTypeName
              : scorecardData?.rolePlayName
          }
        />
        <audio
          controls
          src={scorecardData?.recordedFileUrl}
          style={{ width: '100%', position: 'fixed', bottom: '0' }}
        />
      </>
    )
  );
};

export default AttemptDetailsScorecard;
