import React, { useCallback, useMemo, useState } from 'react';

import { CircularProgress, Grid, Typography } from '@mui/material';
import { ChallengeType, getScoreFrom, THQSelect } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import NaturalDetailsCard from '@/components/insights/role-play/details/naturalDetailsCard/NaturalDetailsCard';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useGetNaturalDetailsData, useGetNRPSections } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const NaturalDetailsTabPanel: React.FC = () => {
  const { t } = useTranslation();

  const initialTypeOptions = useMemo(
    () => [
      { label: t('all'), value: '-' },
      { label: t('goal'), value: ChallengeType.GOAL },
      { label: t('objection'), value: ChallengeType.OBJECTION }
    ],
    [t]
  );

  const initialScoreOptions = useMemo(
    () => [
      { label: t('common.all'), value: '-' },
      { label: t('common.59_or_below'), value: '<59' },
      { label: '60%-69%', value: '<69' },
      { label: '70%-79%', value: '<79' },
      { label: '80%-89%', value: '<89' },
      { label: t('common.90_or_above'), value: '>90' }
    ],
    [t]
  );

  const { tabValue, selectedRolePlay, selectedTypeOption } = useSelectedRPInsightsTabContext();
  const user = useAuthenticatedUserContext();

  const [typeFilter, setTypeFilter] = useState<ChallengeType | '-'>('-');
  const [sectionFilter, setSectionFilter] = useState<string>('-');
  const [scoreFilter, setScoreFilter] = useState<'<59' | '<69' | '<79' | '<89' | '>90' | '-'>('-');

  const sectionOptions = useGetNRPSections(selectedRolePlay?.uuid);
  const mappedOptions = sectionOptions?.map((section) => ({ label: section.section, value: section.uuid }));

  const skip = tabValue !== 1;
  const scores = scoreFilter === '-' ? null : getScoreFrom(scoreFilter);

  const { loading, naturalDetailsData } = useGetNaturalDetailsData(
    selectedRolePlay?.uuid,
    selectedTypeOption,
    skip,
    typeFilter === '-' ? null : typeFilter,
    sectionFilter === '-' ? null : sectionFilter,
    scores?.scoreFrom,
    scores?.scoreTo
  );

  const handleOnChangeTypeFilter = useCallback((e) => {
    setTypeFilter(e.target.value);
  }, []);

  const handleOnChangeSectionFilter = useCallback((e) => {
    setSectionFilter(e.target.value);
  }, []);

  const handleOnChangeScoreFilter = useCallback((e) => {
    setScoreFilter(e.target.value);
  }, []);

  const dataExists = naturalDetailsData?.user?.length > 0;
  return skip ? null : (
    <>
      <Grid container spacing={1} sx={{ marginBottom: '16px' }}>
        <Grid item>
          <THQSelect
            label={t('type')}
            options={initialTypeOptions}
            value={typeFilter}
            onChange={handleOnChangeTypeFilter}
          />
        </Grid>
        <Grid item>
          <THQSelect
            label={t('section')}
            options={mappedOptions}
            value={sectionFilter}
            onChange={handleOnChangeSectionFilter}
          />
        </Grid>
        <Grid item>
          <THQSelect
            label={t('common.score')}
            options={initialScoreOptions}
            value={scoreFilter}
            onChange={handleOnChangeScoreFilter}
          />
        </Grid>
      </Grid>
      {loading ? (
        <Grid sx={{ marginTop: '16px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {dataExists ? (
            <Grid container spacing={2}>
              {naturalDetailsData?.user?.map((pair, i) => (
                <Grid item key={`${pair.uuid}${i}`} md={6} xs={12}>
                  <NaturalDetailsCard
                    compareUserAttempts={naturalDetailsData?.comparingUser?.[i]?.maxPoints}
                    compareUserErrorCount={parseFloat(
                      naturalDetailsData?.comparingUser?.[i]?.achievedPoints?.toFixed(1)
                    )}
                    globalAttempts={naturalDetailsData?.global?.[i]?.maxPoints}
                    globalErrorCount={parseFloat(naturalDetailsData?.global?.[i]?.achievedPoints?.toFixed(1))}
                    isComparing={false}
                    sectionName={t(pair.sectionName)}
                    selectedLearner={user}
                    tabValue={tabValue}
                    title={pair.challengeName}
                    type={pair.challengeType}
                    userAttempts={naturalDetailsData?.user?.[i]?.maxPoints}
                    userErrorCount={parseFloat(naturalDetailsData?.user?.[i]?.achievedPoints?.toFixed(1))}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>{t('no_data_available')}</Typography>
          )}
        </>
      )}
    </>
  );
};

export default NaturalDetailsTabPanel;
