import {
  AvgScoreOverPerMonthData,
  ChallengeEventsCountStats,
  ChallengeScoreRate,
  ErrorsPerPairData,
  Inject,
  Injectable,
  InsightsSummaryStatsResult,
  InsightsUserStatus,
  NaturalDetailsData,
  NRPSection,
  PageRequest,
  ProgressStatus,
  RolePlayCategoryEventsCountData,
  RolePlayErrorsPerAttemptData,
  RolePlayLMSAnalyticsRequest
} from '@trainhq/trainhq-client-core';
import { map, Observable } from 'rxjs';

import { RolePlayAnalyticsRepository } from '@/services/repositoryData/rolePlayAnalyticsRepository';

export interface RolePlayAnalyticsService {
  averageScoreOverTime(request: RolePlayLMSAnalyticsRequest): Observable<AvgScoreOverPerMonthData>;
  exportLatestActivity(request: RolePlayLMSAnalyticsRequest): Observable<any>; // TODO: add proper type for csv data
  exportLeaderboard(request: RolePlayLMSAnalyticsRequest): Observable<any>;
  exportLearners(pageRequest: PageRequest): Observable<void>;
  exportUserValidationReports(pageRequest: PageRequest): Observable<void>;
  getChallengesEventsCountNatural(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeEventsCountStats[]>;
  getChallengeScores(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeScoreRate[]>;
  getEventsCountStats(
    request: RolePlayLMSAnalyticsRequest,
    type: 'strict' | 'natural'
  ): Observable<RolePlayCategoryEventsCountData[]>;
  getErrorsByAttemptsStats(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayErrorsPerAttemptData>;
  getErrorsPerPairPlain(request: RolePlayLMSAnalyticsRequest): Observable<ErrorsPerPairData>;
  getErrorsPerPairStats(request: RolePlayLMSAnalyticsRequest): Observable<number[]>;
  getNaturalDetails(request: RolePlayLMSAnalyticsRequest): Observable<NaturalDetailsData>;
  getNaturalReport(uuid: string): Observable<any>; // TODO: add proper type
  getNaturalSections(rpUuid: string): Observable<NRPSection[]>;
  getUserStatus(rolePlayUuid: string): Observable<InsightsUserStatus>;
  searchLogs(searchParams: any): Observable<any>;
  searchSubmittedNRPReports(searchParams: PageRequest): Observable<any>;
  addSelfAssessment(payload: {
    reportUuid: string;
    goals: { uuid: string; evaluation: ProgressStatus }[];
    objections: { uuid: string; evaluation: ProgressStatus }[];
  }): Observable<any>;
  insightsSummaryStats(request: RolePlayLMSAnalyticsRequest): Observable<InsightsSummaryStatsResult>;
}

@Injectable('rolePlayAnalyticsService')
export class RolePlayAnalyticsServiceImpl implements RolePlayAnalyticsService {
  @Inject('rolePlayAnalyticsRepository')
  private rolePlayAnalyticsRepository: RolePlayAnalyticsRepository;

  averageScoreOverTime(request: RolePlayLMSAnalyticsRequest): Observable<AvgScoreOverPerMonthData> {
    return this.rolePlayAnalyticsRepository.averageScoreOverTime(request);
  }

  exportLatestActivity(request: RolePlayLMSAnalyticsRequest): Observable<void> {
    return this.rolePlayAnalyticsRepository.exportLatestActivity(request);
  }

  exportLeaderboard(request: RolePlayLMSAnalyticsRequest): Observable<void> {
    return this.rolePlayAnalyticsRepository.exportLeaderboard(request);
  }

  exportLearners(pageRequest: PageRequest): Observable<void> {
    return this.rolePlayAnalyticsRepository.exportLearners(pageRequest);
  }

  exportUserValidationReports(pageRequest: PageRequest): Observable<void> {
    return this.rolePlayAnalyticsRepository.exportUserValidationReports(pageRequest);
  }

  getChallengesEventsCountNatural(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeEventsCountStats[]> {
    return this.rolePlayAnalyticsRepository.getChallengesEventsCountNatural(request);
  }

  getChallengeScores(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeScoreRate[]> {
    return this.rolePlayAnalyticsRepository.getChallengeScores(request);
  }

  getEventsCountStats(
    request: RolePlayLMSAnalyticsRequest,
    type: 'strict' | 'natural'
  ): Observable<RolePlayCategoryEventsCountData[]> {
    return type === 'strict'
      ? this.rolePlayAnalyticsRepository.eventsCountStatsStrict(request)
      : this.rolePlayAnalyticsRepository.eventsCountStatsNatural(request);
  }

  getErrorsByAttemptsStats(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayErrorsPerAttemptData> {
    return this.rolePlayAnalyticsRepository.errorsByAttemptsStrict(request);
  }

  getErrorsPerPairStats(request: RolePlayLMSAnalyticsRequest): Observable<number[]> {
    return this.rolePlayAnalyticsRepository.errorsPerPair(request).pipe(
      map((errorPerPair) => {
        if (request.selectedUsername) {
          return errorPerPair.user.map((data) => data.errorCount);
        } else {
          return errorPerPair.global.map((data) => data.errorCount);
        }
      })
    );
  }

  getErrorsPerPairPlain(request: RolePlayLMSAnalyticsRequest): Observable<ErrorsPerPairData> {
    return this.rolePlayAnalyticsRepository.errorsPerPair(request);
  }

  getNaturalDetails(request: RolePlayLMSAnalyticsRequest): Observable<NaturalDetailsData> {
    return this.rolePlayAnalyticsRepository.getNaturalDetails(request);
  }

  getNaturalReport = (uuid: string): Observable<any> => {
    return this.rolePlayAnalyticsRepository.getNaturalReport(uuid);
  };

  getNaturalSections(rpUuid: string): Observable<NRPSection[]> {
    return this.rolePlayAnalyticsRepository.getNaturalSections(rpUuid);
  }

  getUserStatus = (rolePlayUuid: string): Observable<InsightsUserStatus> => {
    return this.rolePlayAnalyticsRepository.getUserStatus(rolePlayUuid);
  };

  searchLogs = (searchParams: any): Observable<any> => {
    return this.rolePlayAnalyticsRepository.searchLogs(searchParams);
  };

  searchSubmittedNRPReports = (searchParams: PageRequest): Observable<any> => {
    return this.rolePlayAnalyticsRepository.searchSubmittedNRPReports(searchParams);
  };

  addSelfAssessment = (payload: {
    reportUuid: string;
    goals: { uuid: string; evaluation: ProgressStatus }[];
    objections: { uuid: string; evaluation: ProgressStatus }[];
  }): Observable<any> => {
    return this.rolePlayAnalyticsRepository.addSelfAssessment(payload);
  };

  insightsSummaryStats = (request: RolePlayLMSAnalyticsRequest): Observable<InsightsSummaryStatsResult> => {
    return this.rolePlayAnalyticsRepository.insightsSummaryStats(request);
  };
}
