import React from 'react';

import { NaturalRoleplayScoreCard } from '@trainhq/trainhq-client-core';

import { useGetAttemptDetailsScorecardData } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const CallDetailsPage = () => {
  const scorecardData = useGetAttemptDetailsScorecardData();

  return (
    <>
      {scorecardData && (
        <NaturalRoleplayScoreCard
          scorecardType="TRANSCRIPT"
          results={scorecardData}
          title={scorecardData.callTypeName}
        />
      )}
      <audio controls src={scorecardData?.recordedFileUrl} style={{ width: '100%', position: 'fixed', bottom: '0' }} />
    </>
  );
};

export default CallDetailsPage;
