import React, { useCallback, useMemo, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';
import {
  blobToFile,
  determineErrorHandling,
  fileToMediaFile,
  GPTCommunicationProblemDialog,
  isOpenaiVoice,
  MediaFile,
  MediaFileUploadService,
  NaturalRoleplayScoreCard,
  RoleplayExecutionConfig,
  RoleplayMode,
  RPValidationCardDialog,
  useMediaFileUploaderService,
  useTextToSpeech,
  useWebSocket
} from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router-dom';
import { map, mergeMap, Observable, of, takeLast } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RP_SESSION_TOPIC, TEXT_TO_SPEECH } from '@/constants/api';
import { HOME } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useAzureSDKContext } from '@/hooks/azureSDK/useAzureSDKContext';
import { SOCKET_URL } from '@/hooks/config/useAppConfig';
import { useRolePlayAnalyticsService } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';
import { useRolePlayService } from '@/hooks/roleplay/useRolePlayService';
import MicPermissionDialog from '@/roleplay/naturalRolePlay/callRolePlay/micPermissionDialog/MicPermissionDialog';
import CallRolePlayStarter from '@/roleplay/naturalRolePlay/callRolePlay/starter/CallRolePlayStarter';
import CallTimeoutDialog from '@/roleplay/naturalRolePlay/callRolePlay/timeoutDialog/CallTimeoutDialog';
import ChatRolePlay from '@/roleplay/naturalRolePlay/ChatRolePlay';
import NaturalRolePlaySelfAssessment from '@/roleplay/naturalRolePlay/naturalRolePlaySelfAssessment/NaturalRolePlaySelfAssessment';
import { TTSReadMessage } from '@/roleplay/naturalRolePlay/utils';
import { RecordingSpeaker } from '@/services/repositoryData/cloud/dto/roleplayCloudDtos';

interface NaturalRolePlayProps {
  initializing: boolean;
  rolePlayExecutionConfig: RoleplayExecutionConfig;
  sessionUuid: string;
  moveOutOfPracticeMode(): void;
  setInitializing: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const NaturalRolePlay: React.FC<NaturalRolePlayProps> = ({
  initializing,
  rolePlayExecutionConfig,
  sessionUuid,
  moveOutOfPracticeMode,
  setInitializing,
  setError
}) => {
  const navigate = useNavigate();

  const { username } = useAuthenticatedUserContext();
  const rpAnalyticsService = useRolePlayAnalyticsService();
  const rpService = useRolePlayService();
  const {
    roleplayConfig,
    roleplayExecutionConfig,
    roleplaySummary,
    createSessionAndFetchConfig,
    getSynth,
    getPlayer,
    getSsmlMessage,
    refreshRoleplaySummary
  } = useAzureSDKContext();
  const mediaFileUploaderService: MediaFileUploadService = useMediaFileUploaderService();

  const { activeCompany } = useAuthenticatedUserContext();

  const [showValidationCard, setShowValidationCard] = useState(false);
  const [submittingValidation, setSubmittingValidation] = useState(false);
  const [currentRunValidationData, setCurrentRunValidationData] = useState(null);
  const [initialSentence, setInitialSentence] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [coveredSteps, setCoveredSteps] = useState([]);
  const [ttsSpeed, setTtsSpeed] = useState<string>('1.0');
  const [validationDialogOpen, setValidationDialogOpen] = useState(false);
  const [GPTErrorDialogOpen, setGPTErrorDialogOpen] = useState(false);
  const [onBeforeSubmit, setOnBeforeSubmit] = useState<() => void>(null);
  const [onCloseSubmit, setOnCloseSubmit] = useState<() => void>(null);
  const [onRestartCallback, setOnRestartCallback] = useState<() => void>(null);

  const uploadPath = useMemo(() => {
    if (roleplayConfig && username) {
      return `${roleplayConfig.rolePlayUuid}/${username}/${sessionUuid}`;
    }
  }, [sessionUuid, roleplayConfig, username]);

  // web sockets session start
  const socket = useWebSocket<unknown>(SOCKET_URL, `${RP_SESSION_TOPIC}/${sessionUuid}`, { skip: !sessionUuid });

  const disableTryAgainButton =
    activeCompany?.companyConfiguration?.rolePlayAttemptsEnabled &&
    roleplaySummary.allAttemptsCount >= activeCompany?.companyConfiguration?.availableRolePlayAttempts;

  const disableSubmit = !chatMessages.find((message) => message.sender === 'me');

  const handleOnUploadCallback = useCallback(
    (mediaFile: MediaFile, speaker: RecordingSpeaker = RecordingSpeaker.BOT) => {
      rpService
        .addRecording({
          sessionUuid,
          mediaFile,
          speaker
        })
        .subscribe();
    },
    [rpService, sessionUuid]
  );
  // text to speech openai
  const { play, pause } = useTextToSpeech({
    url: TEXT_TO_SPEECH,
    onUploadCallback: handleOnUploadCallback
  });

  const moveToScorecard = () => {
    setSubmittingValidation(false);
    setShowValidationCard(true);
  };

  const refreshReport = (): Observable<any> => {
    return rpAnalyticsService.getNaturalReport(currentRunValidationData.reportUuid).pipe(
      map((report) => {
        const flatGoalMap = [];
        report.goalValidationReportData?.sections?.forEach((section) => {
          section.goals.forEach((goal) => {
            flatGoalMap.push({ ...goal, section: section.section });
          });
        });
        const processedResult = {
          ...report,
          conversationHistory: report.conversationHistory
        };

        processedResult.goalValidationReportData.flatGoalMap = flatGoalMap;
        setCurrentRunValidationData(processedResult);
      })
    );
  };

  const handleGoHome = useCallback(() => {
    navigate(HOME);
  }, [navigate]);

  const handleInit = useCallback(() => {
    return rpService
      .init(sessionUuid)
      .pipe(
        tap({
          next: (res) => {
            setInitializing(false);
            if (res?.message) {
              setInitialSentence(true);
              const newChatMessages: any[] = [{ sender: 'bot', content: res?.message }];
              setChatMessages(newChatMessages);
              return true;
            }
          },
          error: () => {
            setError(true);
            return false;
          }
        })
      )
      .subscribe();
  }, [rpService, sessionUuid, setChatMessages, setError, setInitialSentence, setInitializing]);

  const handleRestart = useCallback(
    (methodCallback?: () => void) => {
      // the methodCallback is used in CallTimeoutDialog.tsx
      methodCallback && methodCallback();

      // callback from state was introduced later when the ValidationDialog got the restart button for "Call" roleplay mode
      if (onRestartCallback) {
        setValidationDialogOpen(false);
        onRestartCallback();
      }

      createSessionAndFetchConfig()
        .pipe(
          mergeMap(() => {
            return refreshRoleplaySummary();
          })
        )
        .subscribe({
          next: () => {
            setShowValidationCard(false);
          }
        });
    },
    [createSessionAndFetchConfig, onRestartCallback, refreshRoleplaySummary]
  );

  const handleCloseGptErrorDialog = () => {
    setGPTErrorDialogOpen(false);
  };

  const toggleValidationDialog = (props?: {
    onBeforeSubmit?: () => void;
    onClose?: () => void;
    onRestart?: () => void;
  }) => {
    if (validationDialogOpen) {
      setValidationDialogOpen(false);
      onCloseSubmit?.();
      setOnBeforeSubmit(null);
      setOnCloseSubmit(null);
      setOnRestartCallback(null);
    } else {
      props?.onBeforeSubmit && setOnBeforeSubmit(() => props.onBeforeSubmit);
      props?.onClose && setOnCloseSubmit(() => props.onClose);
      props?.onRestart && setOnRestartCallback(() => props.onRestart);
      setValidationDialogOpen(true);
    }
  };

  const recoverableCallback = () => {
    setGPTErrorDialogOpen(true);
    setSubmittingValidation(false);
  };

  const nonRecoverableCallback = () => {
    setError(true);
  };

  const handleOnChangeSpeedSelect = useCallback((event: SelectChangeEvent) => {
    setTtsSpeed(event.target.value);
  }, []);

  const handleUploadAndSaveAudioRecording = useCallback(
    (mediaFile: MediaFile, speaker: RecordingSpeaker) => {
      mediaFileUploaderService
        .upload(mediaFile.uuid, mediaFile, 'RECORDING', uploadPath)
        .pipe(
          takeLast(1),
          mergeMap((res) => {
            if (res.uploaded) {
              handleOnUploadCallback(res.mediaFile, speaker);
              return of(res);
            }
            throw new Error('Error uploading');
          })
        )
        .subscribe({
          next: () => {
            console.log('media file uploaded.');
          },
          error: (err) => {
            console.log(err);
          }
        });
    },
    [handleOnUploadCallback, mediaFileUploaderService, uploadPath]
  );

  const handleReadMessage = useCallback(
    ({ message, chatMessages, expression, messageEndCallback }: TTSReadMessage) => {
      console.log('Getting synth...');
      if (isOpenaiVoice(roleplayExecutionConfig.roleplayConfig.botVoice)) {
        play({
          message,
          voice: roleplayExecutionConfig.roleplayConfig.botVoice,
          speed: ttsSpeed,
          onEndedCallback: () => {
            //  when openai voice audio ends, trigger the callback - this is used in CallRolePlay.tsx and ChatRolePlay.ts
            if (messageEndCallback) {
              messageEndCallback();
            }
          },
          callback: () => {
            if (chatMessages) {
              setChatMessages(chatMessages);
            }
          }
        });
      } else {
        getSynth().subscribe({
          next: (synth) => {
            // a random big buffer size that should be big enough to hold the audio
            const dataBuffer = new ArrayBuffer(1000000);
            const audioOutputStream = window.SpeechSDK.PullAudioOutputStream.create();
            synth.speakSsmlAsync(
              getSsmlMessage(message, expression ?? 'general', undefined, ttsSpeed),
              function (result) {
                if (result.reason === window.SpeechSDK.ResultReason.Canceled) {
                  console.error('synthesis failed. Error detail: ' + result.errorDetails + '\n');
                }
                if (chatMessages) {
                  setChatMessages(chatMessages);
                }
                console.log('Closing synth....');
                synth.close();
              },
              function (err) {
                window.console.error(err);
              },
              audioOutputStream
            );

            audioOutputStream.read(dataBuffer).then((res) => {
              // slice the buffer to correct size based on the res
              const dataBufferWIthCorrectSize = dataBuffer.slice(0, res);
              const blob = new Blob([dataBufferWIthCorrectSize], { type: 'audio/wav' }); // wav file
              const fileToUpload = blobToFile(blob, 'rp-recording-bot.wav');
              const mediaFile = fileToMediaFile(fileToUpload);
              handleUploadAndSaveAudioRecording(mediaFile, RecordingSpeaker.BOT);
            });
          }
        });
      }
    },
    [
      roleplayExecutionConfig?.roleplayConfig?.botVoice,
      play,
      ttsSpeed,
      getSynth,
      getSsmlMessage,
      handleUploadAndSaveAudioRecording
    ]
  );

  const handlePauseBot = useCallback(() => {
    if (isOpenaiVoice(roleplayExecutionConfig.roleplayConfig.botVoice)) {
      pause();
    } else {
      getPlayer().subscribe((player) => player.pause());
    }
  }, [getPlayer, pause, roleplayExecutionConfig.roleplayConfig.botVoice]);

  const handleSubmitToValidation = async () => {
    setSubmittingValidation(true);
    const history = chatMessages
      .map((item) => ({
        ...item,
        role: item.sender === 'bot' ? 'assistant' : 'user'
      }))
      .reverse();
    const payload = {
      sessionUuid,
      step: Math.floor(initialSentence ? history.length / 2 : (history.length + 1) / 2),
      covered_steps: coveredSteps,
      company: activeCompany?.uuid
    };

    // end the rp session. Validation time is calculated on the backend and is added to the time tracking report.
    await socket.deactivate();

    // in case of 'call' roleplay mode, stop the recording (turn off the stream) when the submit dialog is triggered
    if (onBeforeSubmit) {
      onBeforeSubmit();
    }

    rpService.submitNaturalValidation(payload).subscribe({
      next: (res) => {
        if (res.backgroundExecutorTaskState === 'SUCCESS' && res.validationReportSummary) {
          rpAnalyticsService.getNaturalReport(res.validationReportSummary.reportUuid).subscribe((report) => {
            const flatGoalMap = [];
            report.goalValidationReportData?.sections?.forEach((section) => {
              section.goals.forEach((goal) => {
                flatGoalMap.push({ ...goal, section: section.section });
              });
            });
            const processedResult = {
              ...report,
              conversationHistory: report.conversationHistory
            };
            processedResult.goalValidationReportData.flatGoalMap = flatGoalMap;
            setShowValidationCard(true);
            setCurrentRunValidationData(processedResult);
          });
        } else if (res.backgroundExecutorTaskState === 'ERROR') {
          determineErrorHandling({
            errorCode: res.errorType,
            skipNonRecoverable: false,
            nonRecoverableCallback: nonRecoverableCallback,
            recoverableCallback: recoverableCallback
          });
        }
      },
      error: () => {
        console.log('Error in validation submit');
      },
      complete: () => {
        refreshRoleplaySummary().subscribe();
      }
    });
    setValidationDialogOpen(false);
  };

  const handleDetermineErrorHandling = (error: any) => ({
    errorCode: error.errorBody.error,
    skipNonRecoverable: true,
    nonRecoverableCallback: nonRecoverableCallback,
    recoverableCallback: recoverableCallback
  });

  return submittingValidation ? (
    <NaturalRolePlaySelfAssessment
      refreshReport={refreshReport}
      moveToScorecard={moveToScorecard}
      results={currentRunValidationData}
    />
  ) : !showValidationCard ? (
    <>
      <GPTCommunicationProblemDialog
        open={GPTErrorDialogOpen}
        handleOnClose={handleCloseGptErrorDialog}
        handleOnConfirm={handleCloseGptErrorDialog}
      />
      <RPValidationCardDialog
        disabledSubmit={disableSubmit}
        open={validationDialogOpen}
        variant={rolePlayExecutionConfig?.roleplayConfig?.rolePlayMode === RoleplayMode.CALL ? 'call' : 'chat'}
        onClose={toggleValidationDialog}
        onConfirm={handleSubmitToValidation}
        onRestart={handleRestart}
      />
      {rolePlayExecutionConfig?.roleplayConfig?.rolePlayMode === RoleplayMode.CALL ? (
        <MicPermissionDialog>
          <CallTimeoutDialog
            disableSubmit={disableSubmit}
            onRestart={handleRestart}
            onSubmit={handleSubmitToValidation}
          >
            <CallRolePlayStarter
              chatMessages={chatMessages}
              coveredSteps={coveredSteps}
              initialSentence={initialSentence}
              sessionUuid={sessionUuid}
              determineErrorHandling={handleDetermineErrorHandling}
              init={handleInit}
              pauseBot={handlePauseBot}
              readMessage={handleReadMessage}
              setChatMessages={setChatMessages}
              setCoveredSteps={setCoveredSteps}
              toggleValidationDialog={toggleValidationDialog}
              uploadAndSaveRecording={handleUploadAndSaveAudioRecording}
            />
          </CallTimeoutDialog>
        </MicPermissionDialog>
      ) : (
        <ChatRolePlay
          chatMessages={chatMessages}
          coveredSteps={coveredSteps}
          initialSentence={initialSentence}
          initializing={initializing}
          sessionUuid={sessionUuid}
          showValidationCard={showValidationCard}
          submittingValidation={submittingValidation}
          ttsSpeed={ttsSpeed}
          changeTtsSpeed={handleOnChangeSpeedSelect}
          determineErrorHandling={handleDetermineErrorHandling}
          init={handleInit}
          readMessage={handleReadMessage}
          restart={handleRestart}
          setChatMessages={setChatMessages}
          setCoveredSteps={setCoveredSteps}
          moveOutOfPracticeMode={moveOutOfPracticeMode}
          setCurrentRunValidationData={setCurrentRunValidationData}
          setShowValidationCard={setShowValidationCard}
          setSubmittingValidation={setSubmittingValidation}
          toggleValidationDialog={toggleValidationDialog}
          uploadAndSaveRecording={handleUploadAndSaveAudioRecording}
        />
      )}
    </>
  ) : (
    <>
      <NaturalRoleplayScoreCard
        disableTryAgainButton={disableTryAgainButton}
        scorecardType="EXEC"
        onRestart={handleRestart}
        onGoBack={handleGoHome}
        results={currentRunValidationData}
        title={roleplayConfig?.name}
      />
      <audio
        controls
        src={currentRunValidationData?.recordedFileUrl}
        style={{ width: '100%', position: 'fixed', bottom: '0' }}
      />
    </>
  );
};

export default NaturalRolePlay;
