import React from 'react';

import { CircularProgress, Grid } from '@mui/material';
import {
  AverageScoreGraph,
  AverageScoreOverTime,
  Challenges,
  Completion,
  InsightsSummaryStats
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SelectedTypeOption, useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import {
  useGetAverageScoreOverTimeStats,
  useGetChallengeScores,
  useGetCompletionData,
  useGetInsightsSummaryStats
} from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const NaturalOverviewTabPanel: React.FC = () => {
  const { callTypeUuid } = useParams<{ callTypeUuid: string }>();
  const { tabValue, selectedRolePlay, selectedTypeOption } = useSelectedRPInsightsTabContext();
  const { t } = useTranslation();
  const user = useAuthenticatedUserContext();
  const skip = tabValue !== 0;
  const completionData = useGetCompletionData(
    selectedRolePlay?.uuid,
    selectedRolePlay?.threshold,
    selectedTypeOption,
    callTypeUuid,
    skip
  );

  const avgScoreOverTimeData = useGetAverageScoreOverTimeStats(
    selectedRolePlay?.uuid,
    selectedTypeOption,
    callTypeUuid,
    skip
  );

  const { best, worst, loadingChallenges } = useGetChallengeScores(
    selectedRolePlay?.uuid,
    selectedTypeOption,
    callTypeUuid,
    skip
  );

  const summaryStats = useGetInsightsSummaryStats(selectedRolePlay?.uuid, selectedTypeOption, callTypeUuid, skip);

  const loading = loadingChallenges || avgScoreOverTimeData?.avgScoreOverTimeLoading;

  return (
    <>
      {loading ? (
        <Grid>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {summaryStats && (
              <InsightsSummaryStats
                averageCallDuration={summaryStats.averageDurationSpent}
                lastActivity={summaryStats.lastActivity}
                totalCalls={summaryStats.totalItemsCount}
                totalCallTime={summaryStats.totalDurationSpent}
                totalLearners={summaryStats.totalLearners}
                selectedLearner={true}
                type={selectedTypeOption === SelectedTypeOption.CALLS ? 'calls' : 'rolePlay'}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid alignItems="center" container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Completion completionData={completionData} type="natural" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <AverageScoreGraph
                  averageScore={Number(summaryStats?.averageScore)}
                  type={selectedTypeOption === SelectedTypeOption.CALLS ? 'calls' : 'rolePlay'}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <AverageScoreOverTime
                  avgScoreOverTimeData={avgScoreOverTimeData}
                  selectedUser={`${user.firstName} ${user.lastName}`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Challenges scores={best} title={t('challenges_best_performing')} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Challenges scores={worst} title={t('challenges_worst_performing')} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default NaturalOverviewTabPanel;
