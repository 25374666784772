import React, { useState } from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  THQAccordion,
  THQBody,
  THQCard,
  THQHeadline,
  THQTab,
  THQTabContainer,
  THQTooltip
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import { NRPGoalsContainer, NRPGoalsWrapper, TabPanelStyled } from './styles';
import WatchRoleplayIcon from '@/assets/images/role_play_watch.png';

interface GoalsPanelProps {
  goals: any[];
  objections: any[];
  rolePlayName: string;
  noRPHeader?: boolean;
  roleplayConfig?: any;
  isWatchMode?: boolean;
}

//  TODO: rename this component to ChallengesPanel or something similar
// TODO: refactor the styles, or try reusing the ChallengesPanel component which is much cleaner
const WatchModeGoalsAndObjectionsPanel: React.FC<GoalsPanelProps> = ({
  goals,
  objections,
  rolePlayName,
  noRPHeader,
  roleplayConfig,
  isWatchMode
}) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTabIndex = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <NRPGoalsWrapper>
      {!noRPHeader && (
        <THQCard
          onClick={undefined}
          cardSx={(theme) => ({
            '&&&': {
              maxWidth: '336px'
            },
            ['&:hover']: {
              backgroundColor: `${theme.palette.common.zima}`,
              transition: 'all .3s ease-in'
            },
            transition: 'all .3s ease-out',
            // backgroundColor: `${theme.palette.common.zima}4D`,
            backgroundColor: `${theme.palette.common.zima}`,
            marginBottom: '16px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '300px',
              backgroundColor: `${theme.palette.common.zima}`
            }
          })}
          description={
            <THQTooltip arrow title={<>{rolePlayName}</>}>
              <Typography
                fontSize={12}
                fontWeight={600}
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: { maxWidth: '160px' },
                  maxWidth: '222px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                })}
              >
                {rolePlayName}
              </Typography>
            </THQTooltip>
          }
          icon={<img src={WatchRoleplayIcon} style={{ objectFit: 'cover', height: '40px', width: '40px' }} />}
          orientation="horizontal"
          title={
            <Typography fontSize={12} fontWeight={400} sx={(theme) => ({ color: theme.palette.common.silver })}>
              {t('watch')}
            </Typography>
          }
        />
      )}
      {(!isWatchMode || roleplayConfig?.challengesSidebarEnabledWatchMode) && (
        <NRPGoalsContainer sx={(theme) => ({ marginTop: '100px', height: '80vh' })}>
          <THQHeadline
            sx={{
              marginBottom: '24px',
              fontSize: '18px',
              paddingLeft: '12px',
              paddingRight: '12px'
            }}
          >
            {t('challenges')}
          </THQHeadline>
          <THQTabContainer
            sx={{ paddingLeft: '12px', paddingRight: '12px' }}
            value={tabIndex}
            onChange={handleChangeTabIndex}
          >
            {goals && (
              <THQTab
                tabIndex={0}
                sx={{ fontWeight: 700, marginBottom: '24px', padding: '12px 4px', minWidth: 'unset' }}
                label={t('goals')}
              />
            )}
            {objections && objections?.length > 0 && (
              <THQTab tabIndex={1} sx={{ fontWeight: 700, marginBottom: '24px' }} label={t('objections')} />
            )}
          </THQTabContainer>

          <TabPanelStyled value={tabIndex} index={0}>
            {goals?.map((item, index) => (
              <React.Fragment key={item.uuid}>
                <div>
                  <THQHeadline sx={{ marginBottom: '16px' }}>{item.section}</THQHeadline>
                  <div>
                    {item?.goals?.map((goal, index) => (
                      <THQBody sx={{ marginBottom: '16px' }} key={goal.uuid}>
                        {goal.goal}
                      </THQBody>
                    ))}
                  </div>
                </div>
                {index !== goals?.length - 1 && (
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Divider
                      sx={(theme) => ({
                        borderColor: theme.palette.common.pearl,
                        width: '100%',
                        marginBottom: '32px',
                        marginTop: '8px'
                      })}
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </TabPanelStyled>

          <TabPanelStyled sx={{ paddingTop: '4px' }} value={tabIndex} index={1}>
            {objections?.map((item, index) => (
              <React.Fragment key={item.uuid}>
                <THQAccordion
                  headline={<THQHeadline>{item.objectionMatch}</THQHeadline>}
                  content={
                    <Grid container>
                      <Grid item xs={12} sx={{ marginBottom: '12px' }}>
                        <THQBody>{t('handle_objection')}</THQBody>
                      </Grid>
                      <Grid item xs={12} sx={{ marginBottom: item.handleExample ? '16px' : '0px' }}>
                        <Grid container>
                          {item?.conditions?.map((item, index) => (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                marginLeft: '24px',
                                marginBottom: '16px',
                                width: '95%'
                              }}
                              key={'method_' + index + '_' + item}
                            >
                              <THQBody>{item.conditionText}</THQBody>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      {item.handleExample && (
                        <>
                          <Grid item xs={12}>
                            <THQBody sx={{ marginBottom: '12px' }}>{t('example')}:</THQBody>
                          </Grid>
                          <Grid item xs={12}>
                            <THQBody
                              sx={{
                                fontSize: 12,
                                fontStyle: 'italic',
                                lineHeight: '175%'
                              }}
                            >
                              {item.handleExample}
                            </THQBody>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  }
                />
                {index !== objections?.length - 1 && (
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Divider
                      sx={(theme) => ({
                        borderColor: theme.palette.common.pearl,
                        width: '100%',
                        marginBottom: '32px',
                        marginTop: '8px'
                      })}
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </TabPanelStyled>
        </NRPGoalsContainer>
      )}
    </NRPGoalsWrapper>
  );
};

export default WatchModeGoalsAndObjectionsPanel;
