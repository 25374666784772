import React, { useCallback, useMemo, useState } from 'react';

import { AttemptHistoryTable } from '@trainhq/trainhq-client-core';
import { useLocation } from 'react-router-dom';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import { INSIGHTS, ROLE_PLAY_STRING } from '@/constants/router';
import { useGetSubmittedNRPReports } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';
import { mapTypeOption } from '@/hooks/roleplay/insights/utils';

const getScoreBoundary = (scoreRange: string, upper?: boolean) => {
  switch (scoreRange) {
    case 'All':
      return undefined;
    case '59':
      return upper ? 60 : 0;
    case '69':
      return upper ? 70 : 60;
    case '79':
      return upper ? 80 : 70;
    case '89':
      return upper ? 90 : 80;
    case '90':
      return upper ? 101 : 90;
  }
};

const NaturalResponsesTabPanel: React.FC = () => {
  const location = useLocation();

  const [createdAtBefore, setCreatedAtBefore] = useState(0);
  const [createdAtAfter, setCreatedAtAfter] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [score, setScore] = useState<unknown>('All');

  const { selectedRolePlay } = useSelectedRPInsightsTabContext();

  const isCallsInsights = location.pathname.includes('insights/calls');

  const handleSetCreatedAtAfter = (e) => {
    if (e === null) {
      setCreatedAtAfter(0);
    } else {
      const newTime = new Date(e).setHours(0, 0, 0, 0).valueOf();
      setCreatedAtAfter(newTime);
    }
  };

  const handleSetScore = useCallback((e) => {
    setScore(e.target.value);
  }, []);

  const handleSetCreatedAtBefore = (selectedDate: number) => {
    if (selectedDate === null) {
      setCreatedAtBefore(0);
    } else {
      const newTime = new Date(selectedDate).setHours(0, 0, 0, 0).valueOf();
      setCreatedAtBefore(newTime);
    }
  };

  const handleChangePage = (e, val) => {
    setPage(val);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const handleOpenDetails = (reportUuid: string) => {
    window.open(`${window.location.origin}${INSIGHTS}${ROLE_PLAY_STRING}/${selectedRolePlay?.uuid}/${reportUuid}`);
  };

  const { tabValue, selectedTypeOption } = useSelectedRPInsightsTabContext();

  const request = useMemo(
    () => ({
      rolePlayUuid: selectedRolePlay?.uuid,
      scoreFrom: getScoreBoundary(score as string, false),
      scoreTo: getScoreBoundary(score as string, true),
      createdAtBefore,
      createdAtAfter,
      page,
      rowsPerPage,
      rolePlayTypes: mapTypeOption(selectedTypeOption)
    }),
    [selectedRolePlay?.uuid, score, createdAtBefore, createdAtAfter, page, rowsPerPage, selectedTypeOption]
  );

  const { logResults, loadingLogResults, totalElements } = useGetSubmittedNRPReports(request, tabValue);

  const skip = tabValue !== 2;
  return skip ? null : (
    <AttemptHistoryTable
      createdAtAfter={createdAtAfter}
      createdAtBefore={createdAtBefore}
      isCallsInsights={isCallsInsights}
      loadingLogResults={loadingLogResults}
      logResults={logResults}
      score={score as string}
      onChangeCreatedAtAfter={handleSetCreatedAtAfter}
      onChangeCreatedAtBefore={handleSetCreatedAtBefore}
      onOpenDetails={handleOpenDetails}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      totalElements={totalElements}
      page={page}
      rowsPerPage={rowsPerPage}
      setScore={handleSetScore}
    />
  );
};

export default NaturalResponsesTabPanel;
