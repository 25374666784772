import { useEffect, useMemo, useState } from 'react';

import { PageRequest, PaginationCoordinator, useGetPagination, User } from '@trainhq/trainhq-client-core';
import { finalize, Subscription, tap } from 'rxjs';

import { useCallIntelligenceService } from './useCallIntelligenceContext';

export const useGetCallIntelligencePage = (pageRequest: PageRequest): PaginationCoordinator<any> => {
  const callIntelligenceService = useCallIntelligenceService();
  return useGetPagination<any>(callIntelligenceService.callIntelligenceCallsPage, pageRequest);
};

export const useGetCallIntelligenceCallTypes = (): [fields: any[], loading: boolean] => {
  const callIntelligenceService = useCallIntelligenceService();
  const [callTypes, setCallTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTemplates = useMemo(() => {
    return callIntelligenceService.getAvailableCallTypes().pipe(
      tap((CIFieldOptions) => setCallTypes(CIFieldOptions)),
      finalize(() => {
        setLoading(false);
      })
    );
  }, [callIntelligenceService]);

  useEffect(() => {
    setLoading(true);
    const subs = new Subscription();

    subs.add(
      fetchTemplates.subscribe((res) => {
        console.log('res', res);
      })
    );
    return () => subs.unsubscribe();
  }, [fetchTemplates]);

  return [callTypes, loading];
};

export const useGetCallTypesPage = (pageRequest: PageRequest, skip?: boolean): PaginationCoordinator<any> => {
  const callIntelService = useCallIntelligenceService();
  return useGetPagination<User>(callIntelService.callTypesPage, pageRequest, skip);
};
