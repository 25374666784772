import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import App from '@/App';
import CallDetailsPage from '@/components/callIntelligence/callDetailsPage/CallDetailsPage';
import BlockDetails from '@/components/course/block/BlockDetails';
import CallsInsightsStarter from '@/components/insights/CallsInsightsStarter';
import Insights from '@/components/insights/role-play/Insights';
import RolePlayInsightsStarter from '@/components/insights/RolePlayInsightsStarter';
import { RoleplayDetails } from '@/components/roleplays/roleplayList/roleplayDetails/RoleplayDetails';
import {
  BLOCK,
  CALL_SCORECARD,
  CALLS,
  CALLS_INSIGHTS,
  CALLS_INSIGHTS_STARTER,
  CONFIRM_PASSWORD,
  COURSE_DETAILS,
  COURSES,
  FEEDBACK,
  HOME,
  INSIGHTS,
  JOURNEY,
  JOURNEYS,
  LIBRARY,
  LIBRARY_OVERVIEW,
  NATURAL_ROLE_PLAY_SCORECARD,
  RESET_PASSWORD,
  RESET_PASSWORD_EMAIL,
  RESET_PASSWORD_SMS,
  RESET_PASSWORD_SMS_VERIFY,
  ROLE_PLAY_INSIGHTS,
  ROLE_PLAY_STRING,
  ROLEPLAY_DETAILS,
  ROLEPLAY_INSIGHTS_STARTER,
  ROLEPLAY_START,
  ROLEPLAYS,
  SET_UP_ACCOUNT,
  TOKEN_AUTH
} from '@/constants/router';
import SidebarLayout from '@/layout/SidebarLayout';
import ConfirmPasswordPage from '@/pages/auth/confirmPassword/ConfirmPasswordPage';
import RegisterPage from '@/pages/auth/register/RegisterPage';
import ResetPasswordPage from '@/pages/auth/resetPassword/ResetPasswordPage';
import ResetViaEmail from '@/pages/auth/resetPassword/ResetViaEmail';
import ResetViaSMS from '@/pages/auth/resetPassword/ResetViaSMS';
import SMSCodeVerification from '@/pages/auth/resetPassword/SMSCodeVerification';
import TokenAuthPage from '@/pages/auth/tokenAuth/TokenAuthPage';
import CallsListPage from '@/pages/callIntelligence/CallsListPage';
import CourseDetailsPage from '@/pages/courseDetails/CourseDetailsPage';
import CourseListPage from '@/pages/courseListPage/CourseListPage';
import FeedbackPage from '@/pages/feedback/FeedbackPage';
import HomePage from '@/pages/home/HomePage';
import InsightsPage from '@/pages/insights/InsightsPage';
import JourneyListPage from '@/pages/journey/JourneyListPage';
import JourneyPage from '@/pages/journey/JourneyPage';
import LibraryContentOverviewPage from '@/pages/library/LibraryContentOverviewPage';
import LibraryPage from '@/pages/library/LibraryPage';
import NotFoundPage from '@/pages/notFound/NotFoundPage';
import RoleplayListPage from '@/pages/rolePlay/roleplayList/RoleplayListPage';
import RolePlayPage from '@/pages/rolePlay/RolePlayPage';
import AttemptDetailsScorecardPage from '@/pages/scorecardDetails/AttemptDetailsScorecardPage';
import PrivateRoutes from '@/router/PrivateRoutes';
import PublicRoutes from '@/router/PublicRoutes';

export const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <NotFoundPage />, // TODO: Add proper error boundary that catches and separates bugs from "page not found"
    children: [
      {
        element: <PrivateRoutes />,
        children: [
          {
            element: <SidebarLayout />,
            children: [
              { path: HOME, element: <HomePage /> },
              { path: COURSES, element: <CourseListPage /> },
              { path: JOURNEYS, element: <JourneyListPage /> },
              { path: `${INSIGHTS}`, element: <InsightsPage /> },
              {
                path: `${ROLEPLAY_INSIGHTS_STARTER}/`,
                element: <RolePlayInsightsStarter />,
                children: [{ path: `${ROLE_PLAY_INSIGHTS}/*`, element: <Insights /> }]
              },
              {
                path: `${CALLS_INSIGHTS_STARTER}/`,
                element: <CallsInsightsStarter />,
                children: [{ path: `${CALLS_INSIGHTS}/*`, element: <Insights /> }]
              },
              { path: FEEDBACK, element: <FeedbackPage /> },
              { path: `${LIBRARY}/*`, element: <LibraryPage /> },
              { path: ROLEPLAYS, element: <RoleplayListPage /> },
              { path: CALLS, element: <CallsListPage /> }
            ]
          },
          {
            path: COURSE_DETAILS,
            element: <CourseDetailsPage />,
            children: [{ path: BLOCK, element: <BlockDetails /> }]
          },
          { path: JOURNEY, element: <JourneyPage /> },
          { path: LIBRARY_OVERVIEW, element: <LibraryContentOverviewPage /> },
          { path: ROLEPLAY_DETAILS, element: <RoleplayDetails /> },
          {
            path: ROLE_PLAY_STRING,
            element: <RolePlayPage />,
            children: [{ path: ROLEPLAY_START, element: <RoleplayDetails /> }]
          },
          {
            path: NATURAL_ROLE_PLAY_SCORECARD,
            element: <AttemptDetailsScorecardPage />
          },
          { path: CALL_SCORECARD, element: <CallDetailsPage /> }
        ]
      },
      {
        element: <PublicRoutes />,
        children: [
          { path: TOKEN_AUTH, element: <TokenAuthPage /> },
          { path: SET_UP_ACCOUNT, element: <RegisterPage /> },
          { path: RESET_PASSWORD, element: <ResetPasswordPage /> },
          { path: RESET_PASSWORD_EMAIL, element: <ResetViaEmail /> },
          { path: RESET_PASSWORD_SMS, element: <ResetViaSMS /> },
          { path: RESET_PASSWORD_SMS_VERIFY, element: <SMSCodeVerification /> },
          { path: CONFIRM_PASSWORD, element: <ConfirmPasswordPage /> }
        ]
      }
      // { path: LOGIN, element: <LoginPage /> },
    ]
  }
]);
