import { createContext, useContext } from 'react';

export enum SelectedTypeOption {
  ROLE_PLAY = 'role-play',
  CALLS = 'calls'
}

interface SelectedRPInsightsTabContextProps {
  selectedRolePlay?: { uuid?: string; threshold?: number; name?: string; rolePlayType?: 'STRICT' | 'NATURAL' };
  selectedTypeOption?: SelectedTypeOption;
  tabValue?: number;
}

export const SelectedRPInsightsTabContext = createContext<SelectedRPInsightsTabContextProps>(null);
export const useSelectedRPInsightsTabContext = () => useContext(SelectedRPInsightsTabContext);
