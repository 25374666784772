import React from 'react';

import { Grid } from '@mui/material';
import { THQBody } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

interface AccordionContentProps {
  objection: any;
}

const AccordionContent: React.FC<AccordionContentProps> = ({ objection }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: '12px' }}>
        <THQBody>{t('handle_objection')}</THQBody>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: objection.handleExample ? '16px' : '0px' }}>
        <Grid container>
          {objection?.conditions?.map((item, index) => (
            <Grid
              item
              xs={12}
              sx={{
                marginLeft: '24px',
                marginBottom: '16px',
                width: '95%'
              }}
              key={'method_' + index + '_' + item}
            >
              <THQBody>{item.conditionText}</THQBody>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {objection.handleExample && (
        <>
          <Grid item xs={12}>
            <THQBody sx={{ marginBottom: '12px' }}>Example:</THQBody>
          </Grid>
          <Grid item xs={12}>
            <THQBody
              sx={{
                fontSize: 12,
                fontStyle: 'italic',
                lineHeight: '175%'
              }}
            >
              {objection.handleExample}
            </THQBody>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AccordionContent;
