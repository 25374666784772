import {
  AvgScoreOverPerMonthData,
  ChallengeEventsCountStats,
  ChallengeScoreRate,
  ErrorsPerPairData,
  Inject,
  InsightsSummaryStatsResult,
  InsightsUserStatus,
  NaturalDetailsData,
  NetworkService,
  NRPSection,
  PageRequest,
  ProgressStatus,
  RolePlayCategoryEventsCountData,
  RolePlayErrorsPerAttemptData,
  RolePlayLMSAnalyticsRequest
} from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import {
  ADD_NRP_SELF_ASSESSMENT,
  AVERAGE_SCORE_OVER_TIME,
  CHALLENGE_SCORES,
  EVENTS_COUNT_STATS_NATURAL,
  GET_ERRORS_PER_ATTEMPT_DATA_NATURAL,
  GET_ERRORS_PER_ATTEMPT_DATA_STRICT,
  GET_ERRORS_PER_COUNT_DATA_NATURAL,
  GET_ERRORS_PER_COUNT_DATA_STRICT,
  GET_ERRORS_PER_PAIR_DATA,
  GET_LOGS_ANALYTICS,
  GET_NRP_DETAILS,
  GET_NRP_REPORT,
  GET_NRP_REPORTCARDS,
  GET_NRP_SECTIONS,
  RP_EXPORT_LATEST_ACTIVITY,
  RP_EXPORT_LEADERBOARD,
  RP_EXPORT_LEARNERS,
  RP_EXPORT_USER_VALIDATION_REPORTS,
  RP_INSIGHTS_SUMMARY_STATS,
  RP_INSIGHTS_USER_STATUS
} from '@/constants/api';

export class RolePlayAnalyticsCloudService {
  @Inject('networkService')
  networkService: NetworkService;

  averageScoreOverTime(request: RolePlayLMSAnalyticsRequest): Observable<AvgScoreOverPerMonthData> {
    return this.networkService.post(AVERAGE_SCORE_OVER_TIME, request);
  }

  exportLatestActivity(request: RolePlayLMSAnalyticsRequest): Observable<void> {
    return this.networkService.post(RP_EXPORT_LATEST_ACTIVITY, request);
  }

  exportLeaderboard(request: RolePlayLMSAnalyticsRequest): Observable<void> {
    return this.networkService.post(RP_EXPORT_LEADERBOARD, request);
  }

  exportLearners(pageRequest: PageRequest): Observable<void> {
    return this.networkService.post(RP_EXPORT_LEARNERS, pageRequest);
  }

  exportUserValidationReports(pageRequest: PageRequest): Observable<void> {
    return this.networkService.post(RP_EXPORT_USER_VALIDATION_REPORTS, pageRequest);
  }

  getChallengeScores(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeScoreRate[]> {
    return this.networkService.post(CHALLENGE_SCORES, request);
  }

  getErrorsPerAttemptStatsStrict(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayErrorsPerAttemptData> {
    return this.networkService.post<RolePlayErrorsPerAttemptData>(GET_ERRORS_PER_ATTEMPT_DATA_STRICT, request);
  }

  getErrorsPerAttemptStatsNatural(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayErrorsPerAttemptData> {
    return this.networkService.post<RolePlayErrorsPerAttemptData>(GET_ERRORS_PER_ATTEMPT_DATA_NATURAL, request);
  }

  getErrorsPerPairStats(request: RolePlayLMSAnalyticsRequest): Observable<ErrorsPerPairData> {
    return this.networkService.post<ErrorsPerPairData>(GET_ERRORS_PER_PAIR_DATA, request);
  }

  getChallengesEventsCountNatural(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeEventsCountStats[]> {
    return this.networkService.post<ChallengeEventsCountStats[]>(EVENTS_COUNT_STATS_NATURAL, request);
  }

  getCategoryEventsCountStatsStrict(
    request: RolePlayLMSAnalyticsRequest
  ): Observable<RolePlayCategoryEventsCountData[]> {
    return this.networkService.post<RolePlayCategoryEventsCountData[]>(GET_ERRORS_PER_COUNT_DATA_STRICT, request);
  }

  getCategoryEventsCountNatural(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayCategoryEventsCountData[]> {
    return this.networkService.post<RolePlayCategoryEventsCountData[]>(GET_ERRORS_PER_COUNT_DATA_NATURAL, request);
  }

  getNaturalDetails = (request: RolePlayLMSAnalyticsRequest): Observable<NaturalDetailsData> => {
    return this.networkService.post<NaturalDetailsData>(GET_NRP_DETAILS, request);
  };

  getNaturalSections = (rpUuid: string): Observable<NRPSection[]> => {
    return this.networkService.get<NRPSection[]>(`${GET_NRP_SECTIONS}/?rolePlayUuid=${rpUuid}`);
  };

  getNaturalReport = (uuid: string): Observable<any> => {
    return this.networkService.get<any>(`${GET_NRP_REPORT}?reportUuid=${uuid}`);
  };

  getUserStatus = (rolePlayUuid: string): Observable<InsightsUserStatus> => {
    return this.networkService.get(`${RP_INSIGHTS_USER_STATUS}?rolePlayUuid=${rolePlayUuid}`);
  };

  searchLogs(searchParams) {
    return this.networkService.post<any>(GET_LOGS_ANALYTICS, searchParams);
  }

  searchSubmittedNRPReports = (searchParams: PageRequest): Observable<any> => {
    return this.networkService.post<any>(GET_NRP_REPORTCARDS, searchParams);
  };

  addSelfAssessment = (payload: {
    reportUuid: string;
    goals: { uuid: string; evaluation: ProgressStatus }[];
    objections: { uuid: string; evaluation: ProgressStatus }[];
  }): Observable<any> => {
    return this.networkService.post<any>(`${ADD_NRP_SELF_ASSESSMENT}`, payload);
  };

  insightsSummaryStats = (request: RolePlayLMSAnalyticsRequest): Observable<InsightsSummaryStatsResult> => {
    return this.networkService.post(RP_INSIGHTS_SUMMARY_STATS, request);
  };
}
