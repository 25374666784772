import React, { useCallback } from 'react';

import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { THQAppBar, THQCard } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import ReportsImg from '@/assets/images/reports.png';
import RolePlayImg from '@/assets/images/role-play.png';
import { MainGridStyled } from '@/components/insights/styles';
import { CALLS_INSIGHTS_STARTER, ROLEPLAY_INSIGHTS_STARTER } from '@/constants/router';

const ChooseInsights: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleGoToRolePlayInsights = useCallback(() => {
    navigate(ROLEPLAY_INSIGHTS_STARTER);
  }, [navigate]);

  const handleGoToCallsInsights = useCallback(() => {
    navigate(CALLS_INSIGHTS_STARTER);
  }, [navigate]);

  return (
    <>
      {isTablet ? (
        <THQAppBar text={t('insights')} />
      ) : (
        <MainGridStyled alignItems="center" container justifyContent="space-between" rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm="auto">
            <Typography>{t('insights')}</Typography>
          </Grid>
        </MainGridStyled>
      )}
      <Box height="100%" paddingTop="80px" paddingX={3}>
        <Grid alignItems="center" container height="100%" justifyContent="center">
          <Grid item>
            <Typography align="center" fontWeight={700}>
              {t('common.what_kind_of_insights')}
            </Typography>
            <Typography align="center" color="common.smoke" fontSize={14}>
              {t('common.choose_between_two_options')}
            </Typography>
            <Grid container spacing={2} marginTop={2}>
              <Grid item>
                {/* TODO: VUK: description missing */}
                <THQCard
                  animate
                  description={t('Subtitle is going to be predefined and will be no longer than two lines')}
                  icon={RolePlayImg}
                  selected={false}
                  title={t('roleplay')}
                  onClick={handleGoToRolePlayInsights}
                />
              </Grid>
              <Grid item>
                <THQCard
                  animate
                  description={t('Subtitle is going to be predefined and will be no longer than two lines')}
                  icon={ReportsImg}
                  selected={false}
                  title={t('common.calls_intelligence')}
                  onClick={handleGoToCallsInsights}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChooseInsights;
